import React from "react";
import SEO from "../components/seo";
import Main from "../components/main";

const ProjectsPage = () => (
  <>
    <SEO title="My Projects" keywords={[`project`, `personal project`]} />
    <Main title={"My projects"} projects />
  </>
);

export default ProjectsPage;
